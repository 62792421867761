import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/User/Desktop/SignPenFreeWorking/SignPenFree/docs/public/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "new-user"
    }}>{`New User`}</h1>
    <p>{`Creates a new User.`}</p>
    <p><strong parentName="p">{`POST`}</strong>{` `}<inlineCode parentName="p">{`/users/new`}</inlineCode></p>
    <hr></hr>
    <h4 {...{
      "id": "parameter-specifications"
    }}>{`Parameter Specifications`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Key`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. The Account's API Key.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`User_Active`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. Boolean. Whether or not the new User is active or not. 1=Active/0=Inactive`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`User_First_Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. The new User's first name.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`User_Last_Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. The new User's last name.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`User_Phone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. The new User's Phone number.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`User_Email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Required. String. The new User's Email address.`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`Example Response`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "Result": "1",
  "Error_Messages": {},
  "Account_Id": "d8ff8fad-35a3-4549-8c2c-f3c8e446556c",
  "Data": {
    "User_Id": "28c19e8a-d4d0-4ef4-9d36-68a0f3aecb30",
    "User_Active": 1,
    "User_First_Name": "Jane",
    "User_Last_Name": "Doe",
    "User_Phone": "999-123-4321",
    "User_Email": "janedoe@company.com",
    "Created_By_User_Id": "626c6ff7-9f87-492b-8f5b-9155e24e781a",
    "Created_UTC_DateTime": "2021-10-06 15:12:42"
  }
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      